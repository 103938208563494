import React from 'react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Main = ({ name, header, portfolioItems }) => {
  return ( 
        <main className="site-main">
    <section className="content">
      <div className="name">{name}</div>
      <div className="pagetitle">{header}</div>
    </section>
    <section id="portfolio" className="portfolio">
     {portfolioItems && portfolioItems.map((portfolio, index) => (  
      
            
            <div key={portfolio.id} id={`portfolio-item-${index}`} className="portfolio-item">
              <img src={API_BASE_URL+portfolio.attributes.PortfolioItemImage.data.attributes.url} alt={portfolio.attributes.PortfolioItemImage.data.attributes.name} />
              <div className="portfolio-item-buttons">
              {portfolio.attributes && portfolio.attributes.Produktdokument && portfolio.attributes.Produktdokument.data && portfolio.attributes.Produktdokument.data.attributes && (
                <button onClick={() => window.open(API_BASE_URL + portfolio.attributes.Produktdokument.data.attributes.url, '_blank', 'noopener noreferrer')}
                  id="portfolio-button" className="portfolio-button">{portfolio.attributes.ProduktKnap}</button>
              )}

              {portfolio.attributes && portfolio.attributes.ProduktLink &&  (
                <button onClick={() => window.open( portfolio.attributes.ProduktLink, '_blank', 'noopener noreferrer')}
                  id="portfolio-button" className="portfolio-button">{portfolio.attributes.ProduktKnap}</button>
              )}  
              
              {portfolio.attributes && portfolio.attributes.Beskrivelsedokument && portfolio.attributes.Beskrivelsedokument.data && portfolio.attributes.Beskrivelsedokument.data.attributes && (
                <button onClick={() => window.open(API_BASE_URL + portfolio.attributes.Beskrivelsedokument.data.attributes.url, '_blank', 'noopener noreferrer')}
                  id="portfolio-button" className="portfolio-button">{portfolio.attributes.BeskrivelseKnap}</button>
              )}
              </div>
              <div className="porttitle">{portfolio.attributes.Titel}</div>
              <div className="portbeskrivelse">
                {portfolio.attributes.Beskrivelse.map((paragraph, index) => (
                  <p key={index}>{paragraph.children[0].text}</p>
                ))}
              </div>
            </div>
          ))}     
    </section>
  </main>
  );
};



export default Main;
import axios from 'axios';

// Set the base URL for the API from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch homepage settings
export const fetchHomepageSettings = async () => {
  try {

    const response = await axios.get(`${API_BASE_URL}/api/homepage?populate=*`); 
    return response.data; 
  } catch (error) {
    console.error('Error fetching homepage settings:', error);
    throw error;
  }
};

// Fetch portfolios
export const fetchPortfolios = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/portfolios?populate=*`);
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolios:', error);
    throw error;
  }
};
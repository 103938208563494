import React from 'react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const Header = ( { homepageSettings } ) => {

  const favicon = homepageSettings.data.attributes.favicon;
    
  // Set favicon
  if (favicon && favicon.data && favicon.data.attributes && favicon.data.attributes.url) {
   const faviconUrl = API_BASE_URL + favicon.data.attributes.url;

   // Remove existing favicon link elements
   const existingFavicons = document.querySelectorAll('link[rel="icon"]');
   existingFavicons.forEach(favicon => favicon.parentNode.removeChild(favicon));
   const link = document.createElement('link');
   link.rel = 'icon';
   link.href = faviconUrl;
   document.head.appendChild(link);
 }

 // Set browser tab title 
 if (homepageSettings.data.attributes.name) {
   document.title = homepageSettings.data.attributes.name;
 }

  return (
    <div>
    <header className="MainPage-header">            
      {homepageSettings && <img src={API_BASE_URL + homepageSettings.data.attributes.logo.data.attributes.url} alt="Logo" className="MainPage-logo" />}
      <nav className="nav">
        <ul>
          <li><a href="#portfolio">WORK</a></li>
        </ul>
      </nav>
      
    </header>
    </div>
  );
};

export default Header;
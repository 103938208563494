import React, { useState, useEffect} from 'react';
import './fonts.css';
import './MainPage.css'; 
import Header from './components/header.mjs';
import Main from './components/main.mjs';
import Footer from './components/footer.mjs';
import { fetchHomepageSettings, fetchPortfolios } from './api/api.js';

function MainPage(){
  const [homepageSettings, sethomepageSettings] = useState(null);
  const [portfolioItems, setportfolioItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchhomepageSettings(retries = 3, delay = 1000) {
      try {
        const homepageSettings = await fetchHomepageSettings();
        console.log(homepageSettings);
        sethomepageSettings(homepageSettings);     
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        console.error('Failed to fetch homepage settings:', error);
        if (retries > 0) {
          console.log(`Retrying... (${retries} retries left)`);
          await new Promise(res => setTimeout(res, delay));
          return fetchHomepageSettings(retries - 1, delay);
        } else {
          throw error;
        }

        
      }
    }

    fetchhomepageSettings();
  }, []);

  useEffect(() => {
    async function fetchportfolioItems(retries = 3, delay = 1000) {
      try {
        const portfolioItems = await fetchPortfolios();
        console.log(portfolioItems.data);
        setportfolioItems(portfolioItems.data);
        setLoading(false);
       } catch (error) {
        setError(error);
        setLoading(false);
        console.error('Failed to fetch portfolios:', error);
        if (retries > 0) {
          console.log(`Retrying... (${retries} retries left)`);
          await new Promise(res => setTimeout(res, delay));
          return fetchPortfolios(retries - 1, delay);
        } else {
          throw error;
        }
    
      }
    }

    fetchportfolioItems();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
    return (      
      <div>
        {homepageSettings && (
      <Header homepageSettings={homepageSettings}/>   
    )}

      {homepageSettings && (
      <Main name={homepageSettings.data.attributes.name} header={homepageSettings.data.attributes.header}  portfolioItems={portfolioItems}/>
    )}
    {homepageSettings && (
      <Footer homepageSettings={homepageSettings}/>
    )}
    
    </div>
    );
 
}

export default MainPage;
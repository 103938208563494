import React from 'react';

const Footer = ( { homepageSettings } ) => {
  return (
    <footer className="site-footer">
      <div className="footer-row">
        <div className="footerpmail">  <a href={`mailto:` + homepageSettings.data.attributes.email}>{homepageSettings.data.attributes.email}</a></div>
      </div>
      <div className="footer-row">
        <div className="footerp"><a href={`tel:` + homepageSettings.data.attributes.phone}>{homepageSettings.data.attributes.phone}</a></div>
      
        <div className="footerpLinkedIn">
          <a href={homepageSettings.data.attributes.linkedin} target="_blank" rel="noopener noreferrer">LINKEDIN</a>
        </div>
      </div>
      </footer>
  );
};
 
export default Footer;
// src/App.js
import React from 'react';
import './App.css';
import MainPage from './MainPage.jsx';

function App(){
 
  return (    
    <div className="App">
      <MainPage />  
    </div>
    );
}

export default App;
